import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KuiIconModule } from 'app/key-ui';

export interface LabelBadge {
    label: string,
    name: string,
    color?: string
    showDeleteIcon?: boolean
}

@Component({
    selector: 'key-label-badges',
    standalone: true,
    imports: [NgIf, NgFor, KuiIconModule, NgClass],
    templateUrl: './label-badges.component.html'
})

export class LabelBadgesComponent {

    @Input() labels: LabelBadge[];
    @Input() clickable: boolean;
    @Output() onAction = new EventEmitter();

    toggleDeleteIcon(label: LabelBadge) {
        label.showDeleteIcon = !label.showDeleteIcon;
    }


}
