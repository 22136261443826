import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, Inject, OnChanges, ViewChild, HostListener, OnDestroy, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MatchMediaService, MeasurementUnitsService } from 'app/services';
import { PathOptions, Point } from 'app/shared/components';
import { MapSetLayer } from '@key-telematics/fleet-api-client';
import { takeUntil } from 'rxjs/operators';
import { MapZonesService } from './map-zones.service';
import { MapBounds, MapComponent, MapMarker, MapZone, MapControls, MapCoordinates, MapRoute, MapCoordinateAction } from './map.component';
import { MapSetService } from './mapset.service';

// Ordering here is important
import * as L from 'leaflet';
import 'leaflet-draw';
import * as wmtsTileLayer from 'leaflet-tilelayer-wmts-dg';
import 'leaflet.markercluster';
import 'mapbox-gl-leaflet';
import 'leaflet.gridlayer.googlemutant';
import * as uuid from 'uuid';
import { DOCUMENT } from '@angular/common';
import { MapToolbarComponent } from './map-toolbar/map-toolbar.component';
import { MapToolsService } from './map-tools.service';
import { MapEventsService } from './map-events.service';
import { LayoutGridColumnService } from '../layout-grid/layout-grid-column.service';
import { IKuiDropdownMenuItem } from 'app/key-ui';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { KuiSnackbarService } from 'app/key-ui/snackbar/snackbar.service';
import { MapSearchService } from '../mapsearch';
import { AppService } from 'app/app.service';

import buffer from '@turf/buffer';
import * as turf from '@turf/helpers';
import { MapOptionLayer, MapOptionLayerService, MapOptionMeasurementService, MapOptionPointInformationComponent, MapOptionSearchService, MapOptionService, PointInformationService, ZoneEditorService, MapOptionRoutingComponent, MapOptionRoutingService, ZoneEditorAction, ZoneType, GEOFENCE, ROUTES, MapOptionZoneEditorComponent, MapOptionZonesComponent } from './map-options';
import { PROVIDED_SERVICES } from './map-options/map-options.module';
import { cloneDeep } from 'lodash';
import { ResizeResult } from 'ngxtension/resize';




// nuke the remove all layers option on the delete button
L.EditToolbar.Delete.include({
    removeAllLayers: false,
});


// After upgrading Angular, the circle radius editing starts failing with "Radius is not defined" errors. 
// The following code hacks leaflet.draw.js to fix the issue (from: https://stackoverflow.com/questions/47720555/can-not-adjust-radius-in-leaflet-draw)
// There is a PR to fix the issue from 2020, but it has not yet been merged. When it is merged, this can 
// be removed (https://github.com/Leaflet/Leaflet.draw/pull/968)
L.Edit.Circle = L.Edit.CircleMarker.extend({
    _createResizeMarker: function () {
        const center = this._shape.getLatLng(),
            resizemarkerPoint = this._getResizeMarkerPoint(center);

        this._resizeMarkers = [];
        this._resizeMarkers.push(this._createMarker(resizemarkerPoint, this.options.resizeIcon));
    },

    _getResizeMarkerPoint: function (latlng) {
        const delta = this._shape._radius * Math.cos(Math.PI / 4),
            point = this._map.project(latlng);
        return this._map.unproject([point.x + delta, point.y - delta]);
    },

    _resize: function (latlng) {
        const moveLatLng = this._moveMarker.getLatLng();
        let radius;

        if (L.GeometryUtil.isVersion07x()) {
            radius = moveLatLng.distanceTo(latlng);
        } else {
            radius = this._map.distance(moveLatLng, latlng);
        }

        // **** This fixes the cicle resizing ****
        this._shape.setRadius(radius);

        this._map.fire(L.Draw.Event.EDITRESIZE, { layer: this._shape });
    },
});

export interface LeafletLayerItem {
    title: string;
    visible: boolean;
    featureGroup?: L.FeatureGroup;
    onVisibleChange: (visible: boolean) => void;
}


export interface LeafletLayerInfo {
    [key: string]: LeafletLayerItem;
}

@Component({
    selector: 'key-leaflet-map',
    styleUrls: ['./leaflet.component.scss'],
    templateUrl: './leaflet.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ...PROVIDED_SERVICES
    ]
})
export class LeafletMapComponent extends MapComponent implements AfterViewInit, OnChanges, OnDestroy {

    constructor(
        private app: AppService,
        private ref: ChangeDetectorRef,
        private hostElement: ElementRef,
        public media: MatchMediaService,
        public mapSetService: MapSetService,
        public zonesService: MapZonesService,
        public mapSearchService: MapSearchService,
        public i18n: TranslateService,
        private units: MeasurementUnitsService,
        private mapTools: MapToolsService,
        private mapEvents: MapEventsService,
        private layout: LayoutGridColumnService,
        private snackbar: KuiSnackbarService,
        private mapOptionService: MapOptionService,
        private measurementService: MapOptionMeasurementService,
        private pointInformationService: PointInformationService,
        private layerOptionService: MapOptionLayerService,
        private searchOptionService: MapOptionSearchService,
        private routingOptionService: MapOptionRoutingService,
        private zoneEditorService: ZoneEditorService,
        @Inject(DOCUMENT) public document: Document
    ) {
        super();
    }

    activeControls: MapControls[];
    activeDrawLayer: 'zones' | 'measurements' | 'coordinates';

    /** Map container element  */
    mapElement: HTMLDivElement;
    /** Leaflet instance */
    map: L.Map;

    nonEditableZoneLayer: L.FeatureGroup;
    zoneLayer: L.FeatureGroup;
    zoneEditLayer: L.FeatureGroup;
    zoneRemoveLayer: L.FeatureGroup;
    zoneCreator: L.Draw.Polyline | L.Draw.Polygon;
    zoneEditor: L.EditToolbar.Edit | L.EditToolbar.Delete;

    measurementLayer: L.FeatureGroup;
    measurementPolyline: L.Draw.Polyline;
    measurementDistance: string;

    coordinatesPin: L.Draw.Marker;
    coordinates: MapCoordinates;

    contextMenu: IKuiDropdownMenuItem[];

    layers: LeafletLayerInfo = {
        markers: {
            title: this.i18n.instant('LEAFLET.MARKERS'),
            visible: true,
            onVisibleChange: () => { },
        },
        location: {
            title: this.i18n.instant('SHARED.ZONE_TYPES.LOCATION'),
            visible: true,
            onVisibleChange: () => this.filterVisibleZoneTypes(),
        },
        nogo: {
            title: this.i18n.instant('SHARED.ZONE_TYPES.NOGO'),
            visible: true,
            onVisibleChange: () => this.filterVisibleZoneTypes(),
        },
        keepin: {
            title: this.i18n.instant('SHARED.ZONE_TYPES.KEEPIN'),
            visible: true,
            onVisibleChange: () => this.filterVisibleZoneTypes(),
        },
        route: {
            title: this.i18n.instant('SHARED.ZONE_TYPES.ROUTE'),
            visible: true,
            onVisibleChange: () => this.filterVisibleZoneTypes(),
        },
    };
    overlays: MapOptionLayer[];
    toolbarBaseLayers: MapOptionLayer[];

    markerLayer: L.FeatureGroup;
    nonClusteredMarkerLayer: L.FeatureGroup;

    selectedMarkerLayer: { leafletId: number; id: string } = { leafletId: null, id: null };
    markerLayerIndex: { [id: string]: L.Layer } = {};
    layersControl: any;
    resetControl: L.Control;
    baseLayers: MapSetLayer[];
    selectedBaseLayerIndex = 0;
    errorMessage: string;
    enableMarkerClustering = true;
    body: HTMLElement = this.document.body;
    isFullscreen: boolean;
    isShowingLabels = true;

    polys: {
        [id: string]: L.Layer;
    } = {};
    markerRadiuses: {
        [id: string]: L.Layer;
    } = {};

    @Input() loading = false;

    @ViewChild(MapToolbarComponent, { static: true }) toolbar: MapToolbarComponent;
    @ViewChild('contextMenuRef', { static: true }) contextMenuRef: KuiDropdownComponent;
    @ViewChild('container', { static: true }) containerRef: ElementRef;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.controls) {
            this.activeControls = this.controls;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll();
        this.triggerDestroyed();
    }

    ngAfterViewInit() {
        this.mapElement = this.hostElement.nativeElement.querySelector('.map');

        this.map = L.map(this.mapElement, {
            center: [0, 0],
            zoom: 3,
            minZoom: 1,
            maxZoom: 20,
            attributionControl: false,
            zoomControl: false,
            // animate: false,
        });
        this.map.doubleClickZoom.disable();

        // Adds marker clustering
        if (this.enableMarkerClustering) {
            this.markerLayer = this.createMarkerClusterGroup().addTo(this.map);
        } else {
            this.markerLayer = L.featureGroup().addTo(this.map);
        }

        this.measurementLayer = L.featureGroup().addTo(this.map);
        this.nonClusteredMarkerLayer = L.featureGroup().addTo(this.map);

        this.nonEditableZoneLayer = L.featureGroup().addTo(this.map);
        this.zoneEditLayer = L.featureGroup().addTo(this.map);
        this.zoneRemoveLayer = L.featureGroup().addTo(this.map)
        this.zoneLayer = L.featureGroup().addTo(this.map);
        this.zoneLayer.on('dblclick', e => {
            if (e.propagatedFrom) {
                const event = {
                    layer: 'zone',
                    id: e.propagatedFrom.options.id,
                    options: e.propagatedFrom.options,
                };
                this.mapEvents.emitLayerEvent('dblclick', event);
                this.onLayerDblClick.emit(event);
            }
        });

        this.zoneLayer.on('click', e => {
            if (e.propagatedFrom) {
                const event = {
                    layer: 'zone',
                    id: e.propagatedFrom.options.id,
                    options: e.propagatedFrom.options,
                };
                this.mapEvents.emitLayerEvent('click', event);
                this.onLayerClick.emit(event);
            }
        });

        this.initializeButtons();

        this.mapSetService.mapSet$
            .pipe(takeUntil(this.destroyed)) // automatically unsubscribe when this component is destroyed
            .subscribe(async mapSet => {
                if (mapSet) {
                    for (const layer of mapSet.layers) {
                        if (layer.serverType === 'google') {
                            // if we have a google layer, we need to load the google maps API. Find the google key (assume it's the first server entry) and load it (once only).
                            await this.loadGoogleMapsAPI(layer.servers[0]);
                            break;
                        }
                    }

                    // eh, this is commented out as documentation. It doesn't break anything because you can't select
                    // a different client while any leaflet maps are still in view (happens on separate page).
                    // this.clearMarkers(); // if the map set changes we've changed clients, clear everything
                    setTimeout(() => {
                        this.baseLayers = mapSet.layers;
                        this.addLayersControl();
                    });
                }
            });

        this.map.on('contextmenu', (e: L.LeafletMouseEvent) => {
            const { lat, lng } = e.latlng;
            this.updateContextMenu(lat, lng);

            setTimeout(() => {
                // move the dropdown button to the click position so that it can take over the responsibility of keeping the dropdown-ref in place
                const el: HTMLElement = this.contextMenuRef.el.nativeElement;
                el.style.top = e.containerPoint.y + 'px';
                el.style.left = e.containerPoint.x + 'px';
                this.contextMenuRef.toggle(true);
            });
        });
        this.map.on('viewreset', () => this.onMapMoved.emit());
        this.map.on('zoomend', () => this.onMapMoved.emit());
        this.map.on('moveend', () => this.onMapMoved.emit());

        this.map.on(L.Draw.Event.DRAWVERTEX, (e: L.DrawEvents.DrawVertex) => {
            if (this.activeDrawLayer === 'measurements') {
                const vertexes = e.layers.getLayers();

                if (vertexes.length === 1) {
                    this.measurementLayer.clearLayers();
                }

                let distance = 0;
                vertexes.forEach((vertex, index) => {
                    if (!!index) { // skip first vertex as there are no previous vetexes to compare it to
                        distance += vertex['_latlng'].distanceTo(vertexes[index - 1]['_latlng']);
                    }
                });

                this.measurementDistance = this.units.format(distance / 1000, 'distance').format;
                this.measurementService.setDistance(this.measurementDistance);
            }
        });

        this.map.on(L.Draw.Event.CREATED, async (e: any) => {
            switch (this.activeDrawLayer) {
                case 'coordinates':
                    // aliasing the value of lng as lon seen as that is what coordinates and setMarker calls it
                    const { lat, lng: lon } = e.layer.getLatLng();
                    this.selectCoordinates(lat, lon);
                    break;
                case 'measurements':
                    this.measurementLayer.addLayer(e.layer);
                    this.startMeasurements();
                    break;
                case 'zones':
                    if (e.layerType === 'polyline') {
                        // Routes require at least two points.
                        if (e.layer.getLatLngs()?.length < 2) {
                            // This has less, activate zone editing again.
                            this.activateZoneEditing('zoneaddpolyline', null);
                            break;
                        }
                    }
                    const id = uuid.v4();
                    e.layer.options = { id };
                    this.polys[id] = e.layer;
                    this.setupZoneEditing(id, e.layerType);
                    this.onZoneCreated.emit({
                        id: id,
                        type: e.layerType,
                        points: (e.layerType === 'polygon' ? e.layer.getLatLngs()[0] : e.layer.getLatLngs()).map(ll => ({ x: ll.lng, y: ll.lat })),
                    });
                    break;
            }
        });

        this.map.on(L.Draw.Event.EDITVERTEX, (e: any) => {
            const poly = e.poly;
            let coords = poly.getLatLngs();
            if (Array.isArray(coords[0])) { // polygons have nested arrays
                coords = coords[0];
            }
            this.onPolygonEdited.emit({
                points: coords.map((ll: L.LatLng) => ({ x: ll.lng, y: ll.lat })),
            })
        });

        this.map.on(L.Draw.Event.EDITED, (e: any) => {
            if (this.activeDrawLayer === 'zones') {
                const layers = e.layers;
                const items = {};
                layers?.eachLayer((layer: any) => {
                    if (layer.options.id.includes('-radius')) { // handle proximity radius change
                        const radius = Math.round((layer.getRadius() / 1000) * 100) / 100; // convert radius meters into km and allow up to 2 decimal places
                        const id = layer.options.id.split('-radius')[0];
                        items[id] = {
                            ...items[id],
                            id,
                            radius,
                        };
                    } else { // handle zone change
                        let coords = layer.getLatLngs();
                        if (Array.isArray(coords[0])) { // polygons have nested arrays
                            coords = coords[0];
                        }
                        items[layer.options.id] = {
                            ...items[layer.options.id],
                            id: layer.options.id,
                            points: coords.map((ll: any) => ({ x: ll.lng, y: ll.lat })),
                        };
                    }
                });
                (this.zoneEditor as any).disable();
                this.onZonesEdited.emit({
                    zones: Object.values(items),
                });
            }
        });

        this.map.on(L.Draw.Event.DELETED, (e: any) => {
            if (this.activeDrawLayer === 'zones') {
                const layers = e.layers;
                const items = [];
                layers?.eachLayer(layer => {
                    const id = layer.options.id;
                    items.push({ id });
                    this.removeZone(id);
                });
                (this.zoneEditor as any).disable();
                this.onZonesDeleted.emit({
                    zones: items,
                });
            }
        });

        this.map.addControl(L.control.attribution({ prefix: false }));

        // Show or hide the zoom control depending on viewport size
        this.media.isDesktop.subscribe(this.toggleDesktopControls.bind(this));

        setTimeout(() => {
            this.map.invalidateSize();
            this.setHostHeight();
        });
        this.on(this.layout.layoutGridResized$, () => {
            this.setHostHeight();
        });

        this.on(this.mapSearchService.searchFocus$, point => {
            this.removeZone('mapsearch');
            if (point) {
                if (!isNaN(point.lon) && !isNaN(point.lat) && !isNaN(point.radius)) {
                    this.addZoneRadius('mapsearch', { x: point.lon, y: point.lat }, point.radius || 0.1, 'blue');
                    this.zoomTo(point.lat, point.lon, Math.max(this.getZoom(), 15));
                }
            }
        });

        this.on(this.mapOptionService.fullscreen$, state => {
            if (state) {
                this.enterFullscreen();
            } else {
                this.exitFullscreen();
            }
        });

        this.setupLayerMapOptions();
        this.setupMeasurementMapOptions();
        this.setupZoneMapOptions();
        this.setupPointInformationMapOptions();
        this.setupSearchMapOptions();
        this.setupRoutingMapOptions();
    }

    setupLayerMapOptions() {
        this.on(this.layerOptionService.markerClustering$, clustering => {
            if (clustering) {
                this.showMarkerClusters();
            } else {
                this.hideMarkerClusters();
            }
        });

        this.on(this.layerOptionService.showLabels$, showLabels => {
            if (showLabels) {               
                this.showLabels();
            } else {
                this.hideLabels();
            }
        });
    }

    setupMeasurementMapOptions() {
        this.on(this.measurementService.measuring$, measuring => {
            if (measuring) {
                this.startMeasurements(true);
            } else {
                this.stopMeasurements();
            }
        });

        this.on(this.measurementService.reset$, _ => {
            this.stopMeasurements();
            this.startMeasurements(true);
        })

        this.on(this.measurementService.undo$, _ => {
            this.measurementPolyline?.deleteLastVertex();
        })
    }

    setupPointInformationMapOptions() {
        this.on(this.pointInformationService.pointRequest$, request => {
            if (request) {
                this.getCoordinates();
            } else {
                this.removeCoordinates();
            }
        })
        this.on(this.pointInformationService.openGoogleMaps$, coords => {
            this.openInGoogleMaps(coords);
        })
    }

    setupZoneMapOptions() {
        this.on(this.zoneEditorService.zoneEditEvent$, event => {
            this.activateZoneEditing(event.action, event.zoneId);
        });
        this.on(this.zoneEditorService.zoneEditCancel$, _ => {
            this.cancelZoneEditing();
        });
        this.on(this.zoneEditorService.autoCompleteGeometry$, _ => {
            this.completeShape();
        });
        this.on(this.zoneEditorService.zoneCompleteEdit$, _ => {
            this.saveZoneEditing();
        });
        this.on(this.zoneEditorService.zoneEditUndo$, _ => {
            this.undoZoneEditing();
        })
    }

    setupSearchMapOptions() {
        this.on(this.searchOptionService.markerAdd$, marker => {
            this.addMapMarker(marker);
        });
        this.on(this.searchOptionService.markerRemove$, marker => {
            this.removeMapMarker(marker);
        });
    }

    setupRoutingMapOptions() {
        this.on(this.routingOptionService.markerAdd$, marker => {
            this.addMapMarker(marker);
        });
        this.on(this.routingOptionService.markersRemove$, markers => {
            markers.forEach(marker => this.removeMapMarker(marker));
        });
        this.on(this.routingOptionService.updateRoutes$, routes => {
            this.setRoutes(routes);
        })
        this.on(this.routingOptionService.clearRoutes$, routes => {
            this.clearRoutes(routes);
        })
    }

    onResized(_event: ResizeResult) {
        // this.invalidate();
    }

    setHostHeight() {
        setTimeout(() => {
            this.hostHeight = this.hostElement.nativeElement.clientHeight;
            this.ref.markForCheck();
        });
    }

    initializeButtons() {

        setTimeout(() => {

            this.buttons?.forEach(button => {

                const Button = L.Control.extend(
                    {
                        options: {
                            position: 'topleft',
                        },
                        onAdd: (_map) => {
                            const classes = [
                                'map-button', 
                                'leaflet-draw-toolbar', 
                                'leaflet-bar', 
                                `map-button-${button.id}`, 
                                button.hiddenWhenFullscreen && 'hidden-fullscreen',
                            ].filter(x => x);

                            const controlDiv = L.DomUtil.create('div', classes.join(' '));

                            L.DomEvent
                                .addListener(controlDiv, 'click', L.DomEvent.stopPropagation)
                                .addListener(controlDiv, 'click', L.DomEvent.preventDefault)
                                .addListener(controlDiv, 'click', () => {
                                    this.onButtonClicked.emit(button.id);
                                });

                            if (['icon', 'both'].includes(button.style)) {
                                const icon = L.DomUtil.create('i', 'icon icon-' + button.icon, controlDiv);
                                icon.title = button.title;
                            }

                            if (['text', 'both'].includes(button.style)) {
                                const span = L.DomUtil.create('span', '', controlDiv);
                                span.innerText = button.title;
                            }



                            return controlDiv;
                        },
                    });

                this.map.addControl(new Button());
            });
        }, 100);
    }



    updateContextMenu(lat: number, lon: number) {
        const translate = (str: string) => this.i18n.instant(`LEAFLET.CONTEXT_MENU.${str}`);
        const existingRouting = this.routingOptionService.getRouteInputs();
        const routingHasItems = this.toolbar.controlsIndex.routing && existingRouting && !!existingRouting.length;
        const zones = this.zonesService.findZones(lat, lon);
        const filteredZones = zones.filter(x => x.type !== 'route'); // filter out the routes zones as we not using it here
        this.contextMenu = [
            {
                text: translate('POINT_INFORMATION'), type: 'action', closeDropdownOnClicked: true, action: () => {
                    this.showCoordinatesMapOption();               
                    this.selectCoordinates(lat, lon);
                },
            },
            this.activeControls.includes(MapControls.findclosest) ? {
                text: translate('FIND_CLOSEST'), type: 'action', closeDropdownOnClicked: true, action: () => {
                    this.showCoordinatesMapOption();               
                    this.selectCoordinates(lat, lon, 'find_closest_asset');
                    // if we're fullscreen, exit as the user can't see the results
                    if (this.isFullscreen) {
                        this.exitFullscreen();
                    }

                    
                },
            } : undefined,
            this.app?.features?.page?.mapsearch?.enabled && this.activeControls.includes(MapControls.mapsearch) && filteredZones.length > 0 ? {
                text: translate('MAP_SEARCH'), 
                type: 'action', 
                closeDropdownOnClicked: true, 
                action: async () => {
                    // if we're fullscreen, exit as the user can't see the results
                    if (this.isFullscreen) {
                        this.exitFullscreen();
                    }
                    this.mapSearchService.openZoneSearch(filteredZones[0]);
                }
                ,
            } : undefined,
            this.app?.features?.page?.mapsearch?.enabled && this.activeControls.includes(MapControls.mapsearch) && filteredZones.length === 0 ? {
                text: translate('MAP_SEARCH'), type: 'action', closeDropdownOnClicked: true, action: async () => {
                    this.showCoordinatesMapOption();               
                    // if we're fullscreen, exit as the user can't see the results
                    if (this.isFullscreen) {
                        this.exitFullscreen();
                    }
                    this.selectCoordinates(lat, lon).then(coords => {
                        this.mapSearchService.openPointSearch(lat, lon, coords.address);
                    });
                },
            } : undefined,                          
            this.toolbar.controlsIndex.zoneadd && {
                type: 'divider'
            },
            this.toolbar.controlsIndex.zoneadd && {
                text: translate('NEW_ZONE'), 
                type: 'action', 
                closeDropdownOnClicked: true, 
                action: () => this.showNewZoneMapOptions()
            },
            this.toolbar.controlsIndex.zoneadd && {
                text: translate('NEW_ROUTE'), 
                type: 'action', 
                closeDropdownOnClicked: true, 
                action: () => this.showNewZoneMapOptions(true)
            },
            this.toolbar.controlsIndex.routing && {
                type: 'divider'
            },
            this.toolbar.controlsIndex.routing && {
                text: translate('ROUTE_FROM'), type: 'action', closeDropdownOnClicked: true, action: async () => {
                    const coords = await this.mapTools.getCoordinatesDetails(lat, lon);
                    if (coords.lat && coords.lon) {
                        const inputs = existingRouting;
                        const start = {
                            lat: coords.lat,
                            lon: coords.lon,
                            address: coords.address,
                        };

                        if (routingHasItems) {
                            // keep end position if it exists and replace start position with selected coords
                            const newRoutes = [start, inputs.length > 1 && inputs[inputs.length - 1]].filter(x => x);
                            this.routingOptionService.setRouteInput(newRoutes)
                        } else {
                            this.mapOptionService.clear();
                            // expand toolbar first
                            this.toggleToolbar(true);
                            this.ref.markForCheck();
                            // now open the routing
                            this.mapOptionService.load({
                                component: MapOptionRoutingComponent,
                                data: {
                                    routeInputs: start && [start]
                                },
                                id: MapOptionRoutingComponent.ID,
                                title: MapOptionRoutingComponent.TITLE,
                                icon: MapOptionRoutingComponent.ICON
                            })


                        }
                    } else {
                        this.snackbar.message(this.i18n.instant('LEAFLET.COORDINATES.NO_ITEMS'), null, 'map-marker-slash');
                    }
                },
            },
            this.toolbar.controlsIndex.routing && routingHasItems && {
                text: translate('ROUTE_TO'), type: 'action', closeDropdownOnClicked: true, action: async () => {
                    const coords = await this.mapTools.getCoordinatesDetails(lat, lon);
                    if (coords.lat && coords.lon) {
                        const [start] = existingRouting;
                        // keep start position and replace end position with selected coords
                        this.routingOptionService.setRouteInput([start, {
                            lat: coords.lat,
                            lon: coords.lon,
                            address: coords.address,
                        }]);
                    } else {
                        this.snackbar.message(this.i18n.instant('LEAFLET.COORDINATES.NO_ITEMS'), null, 'map-marker-slash');
                    }
                },
            },
        ].filter(x => x);
        this.ref.markForCheck();
    }

    showCoordinatesMapOption() {
        this.mapOptionService.clear();
        // expand toolbar
        this.toggleToolbar(true);
        this.ref.markForCheck();
        // load the coordinates panel
        this.mapOptionService.load(
            {
                component: MapOptionPointInformationComponent,
                data: {
                    hideCrosshair: true,
                    findclosest: this.toolbar.controlsIndex.findclosest,
                    googlemaps: this.toolbar.controlsIndex.googlemaps,
                    mapsearch: this.toolbar.controlsIndex.mapsearch,
                    routing: this.toolbar.controlsIndex.routing,
                },
                id: MapOptionPointInformationComponent.ID,
                title: MapOptionPointInformationComponent.TITLE,
                icon: MapOptionPointInformationComponent.ICON
            },
        );
    }

    showNewZoneMapOptions(isRoute: boolean = false) {
        this.mapOptionService.clear();
        // expand toolbar
        this.toggleToolbar(true);
        this.ref.markForCheck();
        // load selected map option
        this.mapOptionService.load(
            {
                component: MapOptionZoneEditorComponent,
                data: {
                    editorAction: isRoute ? 'zoneaddpolyline' : 'zoneaddpolygon',
                } as const,
                id: MapOptionZoneEditorComponent.ID,
                title: isRoute ? 'LEAFLET.ZONES.NEW_ROUTE' : 'LEAFLET.ZONES.NEW_ZONE',
                icon: isRoute ? 'pen-line' : 'draw-polygon'
            },
        );
    }

    toggleZoneEditingOptions(visible: boolean) {
        this.mapOptionService.clear();
        // expand toolbar
        this.toggleToolbar(visible);
        this.ref.markForCheck();
        // load selected map option
        if (visible) {
            this.mapOptionService.load({
                component: MapOptionZonesComponent,
                data: {
                    zoneAdd: this.toolbar.controlsIndex.zoneadd,
                    zoneEdit: this.toolbar.controlsIndex.zoneedit,
                    zoneDelete: this.toolbar.controlsIndex.zonedelete
                },
                id: MapOptionZonesComponent.ID,
                title: MapOptionZonesComponent.TITLE,
                icon: MapOptionZonesComponent.ICON
            });
        }
    }

    setRoutes(routes: MapRoute[]) {
        // sort routes to move active route layer to the last position in the array so that it will be displayed as the top layer on the map
        [...routes].sort(a => a.active ? 1 : -1).forEach(route => {
            // in order to make pretty route paths we need to layer different styled polylines on top of each other
            const [border, outerStroke, innerStroke, button] = [`${route.id}.border`, `${route.id}.outerStroke`, `${route.id}.innerStroke`, `${route.id}.click`];
            const coords = route.coords.map(([x, y]) => ({ x, y }));

            this.addPolyline(border, coords, {
                color: '#cecece',
                opacity: 1,
                weight: 10,
            });

            this.addPolyline(outerStroke, coords, {
                color: 'white',
                opacity: 1,
                weight: 8,
            });

            this.addPolyline(innerStroke, coords, {
                color: route.active ? 'red' : '#999',
                opacity: 1,
                weight: 2,
            });

            // had to choose between adding an invisible clickable line on top of the other lines or add listeners to both inner and outer strokes... I went with invisible polyline
            this.addPolyline(button, coords, {
                opacity: 0,
                weight: 10,
            });

            // having click and double click events on the same element doesn't work without a little manual intervention, so being forced to add a timer and check for double click
            let timer = null;
            let isDblClick = false;
            this.polys[button].on('click', () => {
                timer = setTimeout(() => {
                    if (!isDblClick) {
                        const event = {
                            layer: 'route',
                            id: route.id,
                        };
                        this.mapEvents.emitLayerEvent('click', event);
                        this.onLayerClick.emit(event);
                    }
                }, 200);
                isDblClick = false;
            });
            this.polys[button].on('dblclick', () => {
                clearTimeout(timer);
                isDblClick = true;
                const event = {
                    layer: 'route',
                    id: route.id,
                };
                this.mapEvents.emitLayerEvent('dblclick', event);
                this.onLayerDblClick.emit(event);
            });
        });
    }

    clearRoutes(routes: MapRoute[]) {
        (routes || []).forEach(route => {
            const lines = [`${route.id}.border`, `${route.id}.outerStroke`, `${route.id}.innerStroke`, `${route.id}.click`];
            lines.forEach(id => this.removePolyline(id));
        });
    }

    updateZone(zone: MapZone) {
        let poly = this.polys[zone.id];
        const path = poly as L.Path;
        // ensure we have the correct polygon type
        if (path.setStyle) {
            // update to reflect changes in colour / interactivity
            path.setStyle({
                color: zone.color,
                id: zone.id,
                type: zone.type,
                interactive: zone.interactive
            } as any);
        }
        if (path.setTooltipContent) {
            path.setTooltipContent(zone.name);
        }
        if (zone.points) {
            (path as L.Polygon).setLatLngs(zone.points.map(p => [p.y, p.x]));
            this.addZoneRadius(zone.id + '-radius', zone.center, zone.radius);
        }
    }

    addZone(zone: MapZone) {
        if (zone.points) {
            this.addZoneRadius(zone.id + '-radius', zone.center, zone.radius);
        }

        let poly = this.polys[zone.id];
        if (poly) {
            poly.remove();
        }

        if (zone.type === 'route') {
            poly = L.polyline(zone.points.map(p => [p.y, p.x]), {
                color: zone.color,
                opacity: 0.3,
                weight: 5,
                id: zone.id,
                type: zone.type,
                interactive: zone.interactive
            } as any).bindTooltip(zone.name, { sticky: true });
        } else {
            if (zone.points) {
                poly = L.polygon(zone.points.map(p => [p.y, p.x]), {
                    color: zone.color,
                    opacity: 0.3,
                    weight: 2,
                    id: zone.id,
                    type: zone.type,
                    interactive: zone.interactive

                } as any).bindTooltip(zone.name, { sticky: true });
            } else {
                poly = L.circle([zone.center.y, zone.center.x], zone.radius * 1000, {
                    color: zone.color,
                    opacity: 0.3,
                    weight: 2,
                    id: zone.id,
                    interactive: zone.interactive
                } as any).bindTooltip(zone.name, { sticky: true });
            }
        }

        this.polys[zone.id] = poly;
        if (!this.layers[zone.type] || this.layers[zone.type].visible) {
            poly.addTo(zone.interactive ? this.zoneLayer : this.nonEditableZoneLayer);
        }

    }

    addZoneRadius(id: string, center: { x: number, y: number }, radius: number, color = '#999') {
        let poly = this.polys[id];
        if (poly) {
            poly.remove();
            this.nonEditableZoneLayer.removeLayer(poly);
        }

        if (radius && radius > 0) {
            const { x, y } = center;

            poly = L.circle([y, x], radius * 1000, {
                color: color,
                opacity: 0.5,
                fillOpacity: 0.15,
                dashArray: '5 10',
                weight: 1,
                id,
                interactive: false,
            } as any);
            this.polys[id] = poly;
            poly.addTo(this.nonEditableZoneLayer);
        }
    }

    removeZone(id: string) {
        [id, id + '-radius'].forEach(key => { // remove proximity radius too if the zone has one
            const poly = this.polys[key];
            if (poly) {
                poly.remove();
                delete this.polys[key];
                // poly is only removed from the map, we have to remove it from the FeatureGroup ourselves
                if (this.zoneLayer.hasLayer(poly)) {
                    this.zoneLayer.removeLayer(poly);
                }
                if (this.nonEditableZoneLayer.hasLayer(poly)) {
                    this.nonEditableZoneLayer.removeLayer(poly);
                }
            }
        });
    }

    filterVisibleZoneTypes() {
        Object.keys(this.polys).forEach(key => {
            const item = this.polys[key];
            const type = item.options['type'];
            if (this.layers[type]) {
                if (this.layers[type].visible) {
                    item.addTo(this.zoneLayer);
                } else {
                    item.removeFrom(this.zoneLayer as any);
                }
            }
        });
    }


    showError(err: Error) {
        if (err) {
            this.errorMessage = err.message;
            console.error(err);
        } else {
            this.errorMessage = null;
        }
        this.ref.markForCheck();
    }

    /** Resets the map markers  */
    clearMarkers() {
        this.markerLayer?.clearLayers();
        this.zoneRemoveLayer?.clearLayers();
        this.zoneEditLayer?.clearLayers();

        Object.keys(this.markerRadiuses).forEach(id => {
            const poly = this.markerRadiuses[id];
            if (poly) { poly.remove(); }
        });
        this.markerRadiuses = {};

        if (this.nonClusteredMarkerLayer) {

            // go through all of the makers and remove any lines that may have been added
            this.nonClusteredMarkerLayer.getLayers().forEach((l: any) => {
                const id = l.options.id;
                this.removePolyline(id + '-line');
            });

            this.nonClusteredMarkerLayer.clearLayers();

            // if coordinates are present on the map, then reselect them.
            if (this.coordinates) {
                const { lat, lon, action } = this.coordinates;
                this.selectCoordinates(lat, lon, action);
            }
        }
        if (this.map) {
            this.map.invalidateSize();
        }

        if (Object.keys(this.markerLayer.getBounds()).length > 0) {
            this.map.fitBounds(this.markerLayer.getBounds(), {
                padding: [20, 20],
                reset: true,
            } as any);
        }

        // clear all marker references.
        this.markerLayerIndex = {};
    }

    invalidate() {
        if (this.map) {
            try { // this fails sometimes because the leaflet-gl component isn't ready
                this.map.invalidateSize();
            } catch { }
            this.setHostHeight();

        }
    }

    private toggleToolbar(state: boolean) {
        this.toolbar.toggle(state);
        this.onToolbarToggled.emit(state);
    }

    /**
     * Toggles map controls on desktop
     */
    toggleDesktopControls(isDesktop: boolean) {
        const excludeOnMobile = [
            MapControls.zoom,
            MapControls.recenter,
            MapControls.zoneadd,
            MapControls.zoneedit,
            MapControls.zonedelete,
            MapControls.measurements,
            MapControls.routing,
        ];

        this.activeControls = this.controls.filter(x => isDesktop || !excludeOnMobile.includes(x));

        this.ref.markForCheck();
    }

    loadGoogleMapsAPI(key: string): Promise<any> {
        if (window['__onGapiLoaded']) { return; }
        return new Promise((resolve) => {
            window['__onGapiLoaded'] = (_ev) => {
                resolve(window['gapi']);
            };
            const node = document.createElement('script');
            node.src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=__onGapiLoaded`;
            node.type = 'text/javascript';
            document.getElementsByTagName('head')[0].appendChild(node);
        });
    }

    /**
     * Creates a tile layer based on server type
     */
    getTileLayer(layer: MapSetLayer, isOverlay: boolean): L.TileLayer {
        // @see http://leafletjs.com/reference-1.0.3.html#tilelayer
        switch (layer.serverType) {
            case 'osm':
                return L.tileLayer(layer.servers[0], {
                    _type: 'map',
                    minZoom: layer.minZoom || 0,
                    maxZoom: layer.maxZoom || 18,
                    l: layer.layerName,
                    name: layer.name,
                    attribution: layer.attribution,
                    overlay: isOverlay,
                } as any);
            case 'tms':
                return L.tileLayer(layer.servers[0], {
                    tms: true,
                    _type: 'map',
                    minZoom: layer.minZoom || 0,
                    maxZoom: layer.maxZoom || 18,
                    l: layer.layerName,
                    name: layer.name,
                    attribution: layer.attribution,
                    overlay: isOverlay,
                } as any);
            case 'wmts':
                return wmtsTileLayer(layer.servers[0], {
                    _type: 'map',
                    minZoom: layer.minZoom || 0,
                    maxZoom: layer.maxZoom || 18,
                    layer: layer.layerName,
                    name: layer.name,
                    style: 'default',
                    tilematrixSet: 'EPSG:3857',
                    format: 'image/png',
                    attribution: layer.attribution,
                    overlay: isOverlay,
                });
            case 'mbs':
                return L.mapboxGL({
                    _type: 'map',
                    accessToken: 'no-token',
                    style: layer.servers[0],
                    l: layer.layerName,
                    name: layer.name,
                    attribution: layer.attribution,
                    overlay: isOverlay,
                } as any) as any;
            case 'google':
                // google API keys are handled higher up when the Google SDK is loaded.
                return L.gridLayer['googleMutant']({
                    _type: 'map',
                    minZoom: layer.minZoom || 0,
                    maxZoom: layer.maxZoom || 18,
                    type: layer.layerName, // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
                    overlay: isOverlay,
                });

            default:
                return L.tileLayer('#');
        }
    }


    startMeasurements(reset?: boolean) {
        const unit = this.units.format(0, 'distance').unit; // TODO: when the MeasurementUnitService gets refactored find a better way to get the unit alone
        if (reset) {
            this.measurementDistance = '0.00 ' + unit;
            this.activeDrawLayer = 'measurements';
            this.measurementService.setDistance(this.measurementDistance);
        }
        this.measurementPolyline = new L.Draw.Polyline(this.map as any, {
            shapeOptions: {
                color: '#999',
                opacity: 0.8,
                dashArray: '5 10',
            },
            metric: unit === 'km' || unit === 'm',
            feet: unit === 'ft',
            nautic: unit === 'nmi',
        });
        this.measurementPolyline.enable();
    }

    stopMeasurements() {
        this.activeDrawLayer = null;
        this.measurementPolyline.disable();
        this.measurementLayer.clearLayers();
    }

    getCoordinates() {
        if (this.coordinates) {
            this.removeCoordinates();
        }
        this.activeDrawLayer = 'coordinates';
        this.coordinatesPin = new L.Draw.Marker(this.map as any, {
            icon: this.createIcon(0, ''),
        });
        this.coordinatesPin.enable();
    }

    selectCoordinates(lat: number, lon: number, action?: MapCoordinateAction): Promise<MapCoordinates> {
        const emit = async (latitude: number, longitude: number) => {
            this.coordinates = {
                ...await this.mapTools.getCoordinatesDetails(latitude, longitude),
                action: action,
            };
            this.onCoordinatesSelected.emit(this.coordinates);
            this.pointInformationService.setCoordinates(this.coordinates);
            return this.coordinates;
        };
        this.setMarker({
            id: 'coordinates-pin', // using a static id so that the pin will always overwrite the previous one
            lat,
            lon,
            draggable: true,
            dragged: emit,
            getIcon: () => ({ size: 0, html: `<i class="location-icon location-icon__pin map-font-icon__shadows icon icon-map-pin"></i>` }),
        }, this.nonClusteredMarkerLayer);
        return emit(lat, lon);
    }

    removeCoordinates() {
        if (this.coordinatesPin) {
            this.activeDrawLayer = null;
            this.coordinatesPin.disable();
        }
        this.coordinates = null;
        const marker = this.markerLayerIndex['coordinates-pin'];
        if (marker) {
            this.nonClusteredMarkerLayer.removeLayer(marker);
        }
    }

    undoZoneEditing() {
        if (this.zoneCreator && this.zoneCreator.enabled()) {
            this.zoneCreator.deleteLastVertex();
        }
    }

    completeShape() {
        if (this.zoneCreator && this.zoneCreator.enabled()) {
            this.zoneCreator.completeShape();
        }
    }

    saveZoneEditing() {
        if (this.zoneEditor) {
            this.zoneEditor.save();
        }
        this.zoneEditLayer.clearLayers();
    }

    cancelZoneEditing() {
        this.zoneEditLayer.clearLayers();
        this.zoneRemoveLayer.clearLayers();
        this.zoneCreator?.disable();

        if (this.zoneEditor) {
            if (this.zoneEditor['type'] === 'edit') {
                this.zoneEditor.revertLayers();
            }
            (this.zoneEditor as any).disable();
        }
        if (!this.map.hasLayer(this.zoneLayer)) {
            this.map.addLayer(this.zoneLayer);
            this.map.addLayer(this.nonEditableZoneLayer);
        }
        this.zoneCreator = null;
        this.zoneEditor = null;
        this.activeDrawLayer = null;
    }

    activateZoneEditing(type: ZoneEditorAction, zoneId: string) {
        this.cancelZoneEditing();
        this.activeDrawLayer = 'zones';

        switch (type) {
            case 'zoneaddpolyline':
                const unit = this.units.format(0, 'distance').unit;
                this.zoneCreator = new L.Draw.Polyline(this.map as any, {
                    metric: unit === 'km' || unit === 'm',
                    feet: unit === 'ft',
                    nautic: unit === 'nmi',
                });
                this.zoneCreator.enable();
                break;
            case 'zoneaddpolygon':
                this.zoneCreator = new L.Draw.Polygon(this.map as any, {});
                this.zoneCreator.enable();
                break;
            case 'zoneedit':
                if (Object.keys(this.polys).length > 0) {
                    // only allow editing when there are polygons to edit.
                    if (zoneId) {
                        this.setupZoneEditing(zoneId);
                    } else {
                        // no zone id present, allow editing all items on the zone layer.
                        this.zoneEditor = new L.EditToolbar.Edit(this.map as any, { featureGroup: this.zoneLayer } as any);
                        (this.zoneEditor as any).enable();
                    }
                }
                break;
            case 'zonedeletepolygon':
                this.setupZoneRemoval(GEOFENCE);
                break;
            case 'zonedeletepolyline':
                this.setupZoneRemoval(ROUTES);
                break;
            default:
                break;
        }
    }

    private setupZoneEditing(zoneId: string, type?: 'polyline' | 'polygon') {
        const zone = this.polys[zoneId];
        if (!zone) {
            // something is really messed up if we don't have a zone at this point, let's just leave...
            return;
        }
        const zoneType = (zone as any)?.options?.type;
        const isPolylineZone = zoneType === 'route' || type === 'polyline';
        
        // We need to get a copy of the polygon.
        // That way we don't mess with the zone layer during editing.
        const zoneLatLngs = cloneDeep((zone as L.Polyline).getLatLngs());

        const defaultOptions = { 
            color: 'blue',
            opacity: 0.3,
            dashArray: "12",
            ...(zone as L.Polygon)?.options,
        };

        let copyZone: L.Path = L.polygon(zoneLatLngs, defaultOptions);

        if (isPolylineZone) {
            copyZone = L.polyline(zoneLatLngs as any, defaultOptions);
        }
        
        this.zoneEditLayer.clearLayers();
        this.zoneEditLayer.addLayer(copyZone);
        this.zoneEditor = new L.EditToolbar.Edit(this.map as any, { featureGroup: this.zoneEditLayer } as any);
        (this.zoneEditor as any).enable();
        
        // hides all zones whilst editing
        this.map.removeLayer(this.zoneLayer);
        this.map.removeLayer(this.nonEditableZoneLayer);
    }

    private setupZoneRemoval(zoneTypes: ZoneType[]) {
        const suitablePolys = Object.keys(this.polys)
            .map(key => this.polys[key])
            .filter(poly => this.layers[poly.options['type']]?.visible) // only show visible layers
            .filter(poly => zoneTypes.includes(poly.options['type']))

        // hides all zones whilst removing items
        this.map.removeLayer(this.zoneLayer);
        this.map.removeLayer(this.nonEditableZoneLayer);

        if (this.map.hasLayer(this.zoneRemoveLayer)) {
            this.map.removeLayer(this.zoneRemoveLayer);
        }
        this.zoneRemoveLayer = L.featureGroup(suitablePolys);        
        this.map.addLayer(this.zoneRemoveLayer);

        this.zoneEditor = new L.EditToolbar.Delete(this.map as any, { featureGroup: this.zoneRemoveLayer } as any);
        (this.zoneEditor as any).enable();
    }

    resetMap() {
        this.onReset.emit();
        this.selectMarker(null);
        event.stopImmediatePropagation();
    }

    /**
     * Configures and adds the layers control to the map
     */
    addLayersControl() {

        // remove any of the existing map layers
        this.map.eachLayer(mapLayer => {
            if (mapLayer.options && mapLayer.options['_type'] === 'map') {
                mapLayer.remove();
            }
        });

        try {

            const overlays = {};
            const getOverlay = (base, overlay) => {
                const layer = this.baseLayers
                    .find(baseLayer => baseLayer.name === base)
                    .overlays
                    .find(overlayLayer => overlayLayer['name'] === overlay);

                if (!overlays[overlay]) {
                    overlays[overlay] = this.getTileLayer(layer, true);
                }

                return overlays[overlay];
            };

            const triggerBaseLayerChange = (ev: MapOptionLayer) => {

                const baseLayer = this.baseLayers.find(layer => layer.name === ev.id);
                const layerOverlays = baseLayer?.overlays;

                // remove any of the existing map layers and update the toolbar list's visibility
                this.toolbarBaseLayers
                    .filter(layer => layer.id !== ev.id)
                    .forEach(layer => { layer.visible = false; });

                // remove any of the existing map layers
                this.map.eachLayer(mapLayer => {
                    if (mapLayer.options && mapLayer.options['_type'] === 'map') {
                        mapLayer.remove();
                    }
                });

                // remove all other baseLayer's overlays
                this.baseLayers
                    .filter(layer => layer.name !== ev.id)
                    .forEach(layer =>
                        layer.overlays.map(overlay => {
                            this.map.eachLayer(mapLayer => {
                                if (mapLayer.options['overlay'] && mapLayer.options['name'] === overlay['name']) {
                                    mapLayer.remove();
                                }
                            });
                        })
                    );

                // add current baseLayer and its overlays
                this.map.addLayer(ev.meta.layer);
                if (layerOverlays) {
                    layerOverlays.map(overlay => {
                        const overlayLayer = getOverlay(ev.id, overlay['name']);
                        overlayLayer.addTo(this.map);
                    });
                }

                setTimeout(() => {
                    this.map.setMinZoom(baseLayer.minZoom);
                    this.map.setMaxZoom(baseLayer.maxZoom);
                    this.map.invalidateSize();
                    this.setHostHeight();
                });
            };

            this.toolbarBaseLayers = this.baseLayers.map((x, i) => ({
                title: x.name,
                id: x.name,
                visible: i === this.selectedBaseLayerIndex,
                action: triggerBaseLayerChange,
                meta: {
                    layer: this.getTileLayer(x, false),
                },
            }));

            // Add default base layer
            this.toolbarBaseLayers[this.selectedBaseLayerIndex].meta.layer.addTo(this.map);
            const initialBaseLayer = this.baseLayers[this.selectedBaseLayerIndex];

            if (initialBaseLayer.overlays) {
                for (const initialOverlay of initialBaseLayer.overlays) {
                    getOverlay(initialBaseLayer.name, initialOverlay['name']).addTo(this.map);
                }
            }

            // Add layers control
            this.addOverlayLayers();

            this.map.setMinZoom(initialBaseLayer.minZoom);
            this.map.setMaxZoom(initialBaseLayer.maxZoom);
            this.invalidate();
        } catch (err) {
            this.showError(err);
        }
    }

    addOverlayLayers() {

        // Our layers control is not necessarily an accurate reflection of the actual layers used on the map. The zones for instance
        // are broken up into Locations, KeepIn, NoGo and routes, despite all living on the Zones layer (due to needing to edit them together).
        // We create phantom layers where necessary and emit an event when those layers are switched on or off, thereby giving listeners
        // the oportunity to modify themselves if needed.
        this.layers.markers.featureGroup = this.markerLayer;
        this.layers.markers.visible = this.map.hasLayer(this.markerLayer);


        const layers = Object.keys(this.layers).reduce((result, key) => {
            const item = this.layers[key];
            item.featureGroup = item.featureGroup || L.featureGroup(); // create a dummy layer if necessary
            item.featureGroup.on('remove', () => {
                this.layers[key].visible = false;
                if (this.layers[key].onVisibleChange) {
                    this.layers[key].onVisibleChange(this.layers[key].visible);
                }
            });
            item.featureGroup.on('add', () => {
                this.layers[key].visible = true;
                if (this.layers[key].onVisibleChange) {
                    this.layers[key].onVisibleChange(this.layers[key].visible);
                }
            });
            result[item.title] = item.featureGroup;
            if (item.visible) {
                item.featureGroup.addTo(this.map);
            }
            return result;
        }, {});

        this.overlays = Object.keys(this.layers).map(x => ({
            title: this.layers[x].title,
            id: x,
            visible: this.layers[x].visible,
            action: (layer: MapOptionLayer) => {
                if (layer.visible) {
                    this.map.addLayer(layer.meta.layer);
                } else {
                    this.map.removeLayer(layer.meta.layer);
                }
            },
            meta: {
                layer: layers[this.layers[x].title],
            },
        }));

        this.ref.markForCheck();
    }

    /**
     * Initialises and sets up marker clustering
     */
    createMarkerClusterGroup() {
        // Cluster markers on the map (drop-in replacement for L.layerGroup)
        return L.markerClusterGroup({
            maxClusterRadius: (zoom: number) => {
                const maxZoom = 18;
                const range = [10, 80];

                return range[0] + (((range[1] - range[0]) / maxZoom) * (maxZoom - zoom));
            },
            polygonOptions: {
                fillColor: '#ccc',
                fillOpacity: 0.4,
                color: '#bbb',
                dashArray: '5, 10',
            },
            iconCreateFunction: cluster => {

                let size = 'small';
                const children = cluster.getAllChildMarkers();

                if (children.length > 6) {
                    size = 'medium';
                }

                if (children.length > 10) {
                    size = 'large';
                }

                const names = children.map(c => c.options['name']).sort().slice(0, 10);

                if (cluster['_tooltip']) {
                    cluster.unbindTooltip();
                }

                cluster.bindTooltip('<span>' + names.join('<br>') + '</span>', {
                    permanent: false,
                    // hover: true,
                    offset: [20, 0],
                    direction: 'right',
                });

                return L.divIcon({
                    iconSize: [30, 30],
                    className: `marker-cluster marker-cluster-${size}`,
                    html: `<div><span>${cluster.getChildCount()}</span></div>`,
                });
            },
        });
    }

    addRadiusPoly(marker: MapMarker) {
        let poly = this.markerRadiuses['selected-radius'];
        if (this.selectedMarkerLayer) {
            if (this.selectedMarkerLayer?.id === marker.id) {
                if (poly) { poly.remove(); }
                if (marker.radius && marker.radius > 0) {
                    poly = L.circle([marker.lat, marker.lon], marker.radius * 1000, {
                        color: 'gray',
                        opacity: 0.5,
                        fillOpacity: 0.15,
                        dashArray: '1', // without a dasharray the outline is sometimes drawn wonky
                        weight: 1,
                        id: marker.id,
                        interactive: false,
                    } as any);
                    this.markerRadiuses['selected-radius'] = poly;
                    poly.addTo(this.nonEditableZoneLayer);
                }
            }
        } else {
            if (poly) { poly.remove(); }
        }
    }

    /** Updates (or creates) a marker with new information */
    setMarker(marker: MapMarker, layerGroup?: L.FeatureGroup) {
        layerGroup = layerGroup || this.markerLayer;

        if (!layerGroup) {
            throw new Error('The map isn\'t ready yet, call setMarker after the map has initialized');
        }

        // Check if layer already exists
        const alreadyExists = this.markerLayerIndex[marker.id] ? layerGroup.hasLayer(this.markerLayerIndex[marker.id]) : false;
        const layer = this.markerLayerIndex[marker.id];

        let assetTooltipHtml = `<div class='map-marker-color-bar' style="background-color: ${marker.color || 'white'};">&nbsp;</div>`;
        assetTooltipHtml += `<span class='font-sans-serif'>${marker.name}</span>`;
        (marker.flags || []).forEach(flag => {
            assetTooltipHtml += `<i class="icon icon-${flag.icon}" style="color: ${flag.color}"></i>`;
        });
        // assetTooltipHtml += `<i class="icon icon-rectangle-vertical" style="color: ${'red'};"></i>`;



        if (!alreadyExists) {
            const { size, html } = marker.getIcon(false);
            const icon = this.createIcon(size, html);
            const newMarker = L.marker(
                [marker.lat, marker.lon],
                {
                    icon: icon,
                    id: marker.id,
                    name: marker.name,
                    info: marker,
                    draggable: marker.draggable,
                    autoPan: marker.draggable,
                    tooltipAnchor: icon.options.tooltipAnchor,
                } as any
            );
            if (marker.click) {
                newMarker.on('click', marker.click as any);
            }
            if (marker.dragged) {
                newMarker.on('dragend', e => {
                    const { lat, lng } = e.target.getLatLng();
                    marker.dragged(lat, lng);
                });
            }
            layerGroup.addLayer(newMarker);

            if (marker.name) {
                newMarker.bindTooltip(assetTooltipHtml, {
                    permanent: this.isShowingLabels,
                    direction: 'right',
                    pane: 'markerPane',
                });
            }

            this.addRadiusPoly(marker);

            this.markerLayerIndex[marker.id] = newMarker;
        } else {
            this.addRadiusPoly(marker);

            layer.options['name'] = marker.name;
            layer.options['info'] = marker;
            const icon = marker.getIcon(layer.options['selected']);
            (layer as any)
                .setIcon(this.createIcon(icon.size, icon.html))
                .setLatLng([marker.lat, marker.lon])
                .update();
            layer.setTooltipContent(assetTooltipHtml);
        }

    }

    createIcon(size: number, html: string) {

        let iconAnchor = [size / 2, size / 2];
        const anchorRegex = /anchor="(.*?)"/gm;
        const matches = anchorRegex.exec(html);
        if (matches) {
            const anchorStr = matches[1] || `${size / 2} ${size / 2}`;
            iconAnchor = anchorStr.split(' ').map(x => parseFloat(x));
        }

        return L.divIcon({
            iconSize: [size, size],
            iconAnchor: iconAnchor as any,
            tooltipAnchor: [
                size / 2, // offset it to the right
                (size / 2) - iconAnchor[1], // take the actual anchor into account (should usually be 0)
            ],
            className: 'position-marker',
            html: html,
        });
    }

    zoomTo(lat: number, lon: number, zoom: number) {
        this.map.setView([lat, lon], zoom, { animate: true });
    }

    zoomToBounds(bounds: MapBounds) {
        if (!bounds) { return null; }
        this.map.fitBounds(L.latLngBounds([bounds.top, bounds.left], [bounds.bottom, bounds.right]), {
            padding: [20, 20],
            animate: false,
            maxZoom: 15,
        });
    }

    getMarkerBounds(): MapBounds {
        if (!this.markerLayer || this.markerLayer['length'] === 0) { return null; }
        const bnds = this.markerLayer.getBounds();
        if (!bnds.isValid()) { return null; }
        return {
            left: bnds.getWest(),
            top: bnds.getNorth(),
            right: bnds.getEast(),
            bottom: bnds.getSouth(),
        };
    }


    selectMarker(id: string, pan = true, zoom?: number) {
        // Lot's of UI changes may happen when an asset is selected. This function is already called next tick.
        setTimeout(() => this.invalidate());

        let latLng = null;

        const previousLayer: any = this.selectedMarkerLayer && this.markerLayer.getLayer(this.selectedMarkerLayer.leafletId);
        const currentLayer: any = id && this.markerLayerIndex[id];

        if (previousLayer) {
            const iconDetails = previousLayer['options'].info.getIcon(false);
            const icon = this.createIcon(iconDetails.size, iconDetails.html);
            previousLayer.setIcon(icon);
            previousLayer.options.selected = false;
            // Reset zIndex
            previousLayer.setZIndexOffset(0);
            // Reset tooltip anchor
            previousLayer.options.tooltipAnchor = icon.options.tooltipAnchor;

            if (previousLayer._tooltip && this.isShowingLabels) {
                if (previousLayer._tooltip._container) {
                    previousLayer._tooltip._container.style.zIndex = previousLayer._zIndex;
                }

                previousLayer.closeTooltip();
                previousLayer.openTooltip();
            }

            const marker = previousLayer.options?.info as MapMarker;
            if (marker) {
                for (const link of (marker.links || [])) {
                    this.removeMapMarker(link);
                    this.removePolyline(link.id + '-line');
                }
            }


        }

        if (currentLayer) {
            if (!currentLayer['options'].selected) {
                const iconDetails = currentLayer['options'].info.getIcon(true);
                const icon = this.createIcon(iconDetails.size, iconDetails.html);
                currentLayer.setIcon(icon);
                currentLayer.options.selected = true;
                // Update zIndex to overlap others if needed
                currentLayer.setZIndexOffset(1000);
                // Reset tooltip anchor
                currentLayer.options.tooltipAnchor = icon.options.tooltipAnchor;

                if (currentLayer._tooltip && this.isShowingLabels) {
                    if (currentLayer._tooltip._container) {
                        currentLayer._tooltip._container.style.zIndex = currentLayer._zIndex;
                    }

                    currentLayer.closeTooltip();
                    currentLayer.openTooltip();
                }
            }
            latLng = currentLayer.getLatLng();

            this.selectedMarkerLayer = { id, leafletId: currentLayer._leaflet_id };

            const marker = currentLayer.options?.info as MapMarker;
            if (marker) {
                this.addRadiusPoly(marker);
                for (const link of (marker.links || [])) {
                    this.addMapMarker(link);
                    this.addPolyline(link.id + '-line', [{ x: marker.lon, y: marker.lat }, { x: link.lon, y: link.lat }], { color: 'gray', dashArray: '5', opacity: 0.5 });
                }
            }

        }

        // deselected an asset, return zoom to overview
        if (!id && Object.keys(this.markerLayer.getBounds()).length > 0 && pan) {
            this.map.fitBounds(this.markerLayer.getBounds().extend(this.nonClusteredMarkerLayer.getBounds()), {
                padding: [20, 20],
                animate: false,
            });
            return;
        }

        if (pan && latLng) {
            // flyTo gives a nice animation for this, but used more data and tends to be slower
            this.map.setView(latLng, zoom ? zoom : this.getZoom(), { animate: true });
        }
    }

    getZoom(): number {
        return this.map?.getZoom() || 12;
    }

    getBounds(): MapBounds {
        const bnds = this.map.getBounds();
        return {
            left: bnds.getWest(),
            top: bnds.getNorth(),
            right: bnds.getEast(),
            bottom: bnds.getSouth(),
        };
    }

    getCenter(): Point {
        const center = this.map.getCenter();
        return {
            x: center.lng,
            y: center.lat,
        };
    }

    openInGoogleMaps(coordinates: MapCoordinates) {
        const { lat, lon } = coordinates;
        window.open(`http://maps.google.com/?ll=${lat},${lon}&z=${this.getZoom()}&q=${lat},${lon}`);
    }

    addPolyline(id: string, latLngs: Point[] = [], options: PathOptions = {}) {
        if (id in this.polys) {
            this.removePolyline(id);
        }
        if (latLngs?.length >= 2) {
            if (options?.buffer) {
                const l1 = turf.lineString(latLngs.map(item => ([item.x, item.y])));
                const buffered = buffer(l1, 5, { units: 'meters', steps: 8 });
                this.polys[id] = L.polygon(buffered.geometry.coordinates.map(a => a.map(x => ([x[1], x[0]]))) as any, {
                    color: options.color || 'blue',
                    opacity: options.opacity,
                    weight: options.weight,
                    dashArray: options.dashArray,
                    id: id,
                } as any);
            } else {
                this.polys[id] = L.polyline(latLngs.map(this.pointToLatLng), options);
            }
            this.polys[id].on('click', () => {
                const event = {
                    layer: 'polyline',
                    id: id,
                };
                this.mapEvents.emitLayerEvent('click', event);
                this.onLayerClick.emit(event);
            });
            if (options.tooltip) {
                this.polys[id].bindTooltip(options.tooltip, { sticky: true });
            }
            this.polys[id].addTo(this.map);
        }
    }

    removePolyline(id: string) {
        if (id in this.polys) {
            this.polys[id].remove();
        }
    }

    disableMarkerClustering() {
        this.enableMarkerClustering = false;
    }

    getPixelDistance(start: Point, end: Point): number {
        // yo bro pls make sure map is zoomed for this
        const startCoord = this.map.latLngToLayerPoint(this.pointToLatLng(start));
        const endCoord = this.map.latLngToLayerPoint(this.pointToLatLng(end));

        const x = (endCoord.x - startCoord.x) ** 2;
        const y = (endCoord.y - startCoord.y) ** 2;

        return Math.sqrt(x + y);
    }

    getBoundsZoom(bounds: MapBounds): number {
        const realBounds: L.LatLngBoundsExpression = [
            [bounds.top, bounds.left],
            [bounds.bottom, bounds.right],
        ];

        return this.map.getBoundsZoom(realBounds);
    }

    getPointBounds(points: Point[]): MapBounds {
        const bounds = L.latLngBounds(points.map(this.pointToLatLng));
        return {
            left: bounds.getWest(),
            top: bounds.getNorth(),
            right: bounds.getEast(),
            bottom: bounds.getSouth(),
        };
    }

    setZoom(zoom: number) {
        this.map.setZoom(zoom);
    }

    pointToLatLng(point: Point): L.LatLngTuple {
        return [point.y, point.x];
    }

    enterFullscreen() {
        const container = this.hostElement.nativeElement;
        if (!this.isFullscreen) {
            if (container.requestFullscreen) {
                container.requestFullscreen();
            } else if (container.mozRequestFullScreen) {
                container.mozRequestFullScreen();
            } else if (container.webkitRequestFullscreen) {
                container.webkitRequestFullscreen((Element as any).ALLOW_KEYBOARD_INPUT);
            } else if (container.msRequestFullscreen) {
                container.msRequestFullscreen();
            } else {
                container.classList.add('fullscreen-on');
            }

            this.body.classList.add('disable-smooth-scrolling');
            this.isFullscreen = true;
            this.ref.markForCheck();
        }
    }

    exitFullscreen() {
        const container = this.hostElement.nativeElement;
        const doc = this.document as any; // casting this to any because we are using browser specific methods

        if (doc.exitFullscreen) {
            doc.exitFullscreen();
        } else if (doc.mozCancelFullScreen) {
            doc.mozCancelFullScreen();
        } else if (doc.webkitCancelFullScreen) {
            doc.webkitCancelFullScreen();
        } else if (doc.msExitFullscreen) {
            doc.msExitFullscreen();
        } else {
            container.classList.remove('fullscreen-on');
        }

        this.body.classList.remove('disable-smooth-scrolling');
        this.isFullscreen = false;
        this.ref.markForCheck();
    }

    toggleFullscreen() {
        if (!this.isFullscreen) {
            this.enterFullscreen();
        } else {
            this.exitFullscreen();
        }
    }

    async addMapMarker(marker: MapMarker) {
        this.setMarker(marker, this.nonClusteredMarkerLayer);
        // getting the markerLayer bounds might be a little excessive, but I don't care... it is a fallback
        const { lat, lng } = this.nonClusteredMarkerLayer.getBounds && this.nonClusteredMarkerLayer.getBounds().getCenter();
        const { top, bottom, left, right } = (marker.getBounds ? marker.getBounds(marker.bounds) : marker.bounds) || {
            top: marker.lat || lat,
            bottom: marker.lat || lat,
            left: marker.lon || lng,
            right: marker.lon || lng,
        };
        this.map.fitBounds([
            [top, left],
            [bottom, right],
        ]);
        const coordinates = await this.mapTools.getCoordinatesDetails(marker.lat, marker.lon);
        this.onMarkerAdded.emit({ coordinates, id: marker.id });
    }

    removeMapMarker(marker: Partial<MapMarker>) {
        if (marker) {
            const markerInstance = this.markerLayerIndex[marker.id];
            if (markerInstance) {
                if (this.nonClusteredMarkerLayer.hasLayer(markerInstance)) {
                    this.nonClusteredMarkerLayer.removeLayer(markerInstance);
                }
                if (this.markerLayer.hasLayer(markerInstance)) {
                    this.markerLayer.removeLayer(markerInstance);
                }
                markerInstance?.remove();
                delete this.markerLayerIndex[marker.id];
            }
        }
    }

    hideMarkerClusters() {
        if (this.enableMarkerClustering) {
            const overlay = this.overlays?.find((o) => o.id = 'marker');
            if (overlay) {
                this.map.removeLayer(overlay.meta.layer);
                // create a non clustered feature group
                const markers = L.featureGroup(Object.values(this.markerLayerIndex));
                // sync our new layer with the current overlay
                overlay.meta.layer = markers;
                this.markerLayer = markers;
                overlay.action(overlay);
            }
        }
    }

    showMarkerClusters() {
        if (this.enableMarkerClustering) {
            const overlay = this.overlays?.find((o) => o.id = 'marker');
            if (overlay) {
                this.map.removeLayer(overlay.meta.layer);
                // create a non clustered feature group
                const clusteredMarkers = this.createMarkerClusterGroup().addLayers(Object.values(this.markerLayerIndex));
                // sync our new layer with the current overlay
                overlay.meta.layer = clusteredMarkers;
                this.markerLayer = clusteredMarkers;
                overlay.action(overlay);
            }
        }
    }

    getMapOptionService(): MapOptionService {
        return this.mapOptionService;
    }

    private hideLabels() {
        this.isShowingLabels = false;
        const markers = Object.values(this.markerLayerIndex);
        markers.forEach(marker => {
            const tooltip = marker.getTooltip();
            if (tooltip?.options?.permanent) {
                marker.unbindTooltip().bindTooltip(tooltip, {
                    permanent: false
                });
            }
        });
    }

    private showLabels() {
        this.isShowingLabels = true;
        const markers = Object.values(this.markerLayerIndex);
        markers.forEach(marker => {
            const tooltip = marker.getTooltip();
            if (tooltip) {
                marker.unbindTooltip().bindTooltip(tooltip, {
                    permanent: true
                });
            }
        });
    }

    @HostListener('fullscreenchange')
    @HostListener('webkitfullscreenchange')
    @HostListener('mozfullscreenchange')
    @HostListener('MSFullscreenChange')
    handleFullscreenChange() {
        // A user can exit out of fullscreen via other methods, not just the fullscreen button,
        // make sure we keep the state in sync.
        this.isFullscreen = !!document.fullscreenElement;
    }

}
