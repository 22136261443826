import { Component, Input, ChangeDetectionStrategy, HostBinding, OnChanges, SimpleChanges, inject, EventEmitter, Output } from '@angular/core';
import { getIconStyles } from '../utils';
import { ImageService } from 'app/services/images/image.service';
import { TripItem } from 'app/services/trips/trips.service';
import { LabelListItem } from '@key-telematics/fleet-api-client';
export interface KeyDetailsBadge {
    icon?: string;
    text: string;
    hint: string;
}
@Component({
    selector: 'key-details-overview-card',
    templateUrl: './details-overview-card.component.html',
    styleUrls: ['./details-overview-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class KeyDetailsOverviewCardComponent implements OnChanges {
    private images = inject(ImageService);
    @Input() icon: { name: string, color: string };
    @Input() title: string;
    @Input() badges: KeyDetailsBadge[] = [];
    @Input() assetId: string;
    @Input() trip: TripItem;
    @Input() labelsEnabled: boolean;
    @Input() allLabels: LabelListItem[];
    @Input() showLabelListSpinner: boolean;
    imageUrl: string;

    @Output() labelBadgeAction = new EventEmitter();
    @Output() labelListAction = new EventEmitter();

    @HostBinding('class.details-overview') hostClass = true;
    
    async ngOnChanges(changes: SimpleChanges) {
        if (changes.assetId && this.assetId) {
            this.imageUrl = await this.getAssetAvatar();
        }
    }

    getIconStyles(color: string): { color: string, background: string } {
        return getIconStyles(color);
    }

    async getAssetAvatar(): Promise<string> {
        if (this.images?.getForAsset) {
            return await this.images?.getForAsset(this.assetId);
        }
        return '';
    }
}
