<div class="p-3">
    <div class="d-flex">
        <div *ngIf="icon && !imageUrl"
            class="details-icon mr-3">
            <!-- icons will probably load as images, so keeping the markup for it -->
            <!--<img [attr.src]="icon" class="rounded-circle img-fluid">-->
            <div class="d-flex align-items-center justify-content-center details-overview__icon-container"
                [ngStyle]="getIconStyles(icon?.color)">
                <kui-icon [name]="icon?.name"
                    [size]="2.25"></kui-icon>
            </div>
        </div>
        <div *ngIf="imageUrl"
            class="details-icon mr-3">
            <div class="d-flex align-items-center justify-content-center details-overview__icon-container">
                <img [src]="imageUrl"
                    class="rounded-circle img-fluid">
            </div>
        </div>
        <div class="flex-stretch">
            <div class="d-flex flex-wrap">
                <div>
                    <h3 *ngIf="title"
                        class="mb-2 flex-1"
                        [innerHtml]="title"></h3>
                    
                </div>
                <kui-badge [style]="'secondary'"
                    *ngFor="let badge of badges"
                    [title]="badge.hint"
                    class="mb-2 muted-less">
                    <kui-icon *ngIf="badge.icon"
                        [name]="badge.icon"
                        class="mr-2"></kui-icon>{{ badge.text }}
                </kui-badge>
            </div>
            <ng-content></ng-content>

            @if (labelsEnabled && !trip?.inprogress) {
                
                @if (trip?.labels?.length > 0) {
                    <div class="mt-1">
                        <key-label-badges [labels]="trip.labels" [clickable]="true" (onAction)="labelBadgeAction.emit($event)"></key-label-badges>
                    </div>
                }
                
                @if (allLabels) {
                    <div class="mt-1">
                        <key-label-dropdown-list [labelOptions]="allLabels" [showLabelListSpinner]="showLabelListSpinner" (onAction)="labelListAction.emit($event)"/>
                    </div>
                }
            }
        </div>
    </div>
</div>